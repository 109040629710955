<script>
import { authComputed } from "@/state/helpers";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/preloader";
import appConfig from "@/app.config";
import EventBus from "@/event-bus";
import Repository from "@/app/repository/repository-factory";

const NotificationRepository = Repository.get("NotificationRepository");

export default {
  page: {
    title: "Powiadomienia",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
  },
  data() {
    return {
      title: "Powiadomienia",
      items: [
        {
          text: "Strona główna",
          to: {
            name: "home",
          },
        },
        {
          text: "Powiadomienia",
          active: true,
        },
      ],
      preloader: true,
      currentPage: 1,
      countNotificationAll: 10,
      countNotificationUnread: 10,
      perPage: 10,
      startIndex: 1,
      endIndex: 10,
      loggedUser: null,
      userNotifications: {},
      todayDate: new Date().toISOString().slice(0, 10),
      isProcessing: false,
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    this.getAll(1, 10, this.paramType, this.$route.params.module);
    EventBus.$on("notificationChanged", () => {
      this.getAll(1, 10, this.paramType, this.$route.params.module);
    });
  },
  watch: {
    currentPage: function (val) {
      this.getAll(val, 10, this.paramType, this.$route.params.module);
    },
    $route(to, from) {
      this.getAll(1, 10, this.paramType, this.$route.params.module);
    },
  },
  methods: {
    ...authComputed,
    markAsReadAll() {
      NotificationRepository.markAsReadAll()
        .then(() => {
          this.countNotificationUnread = 0;
          this.getAll(1, 10, this.paramType, this.$route.params.module);
          EventBus.$emit("notificationChanged");
          this.$notify({
            title: "Sukces!",
            dangerouslyUseHTMLString: false,
            message:
              "Wszystkie powiadomienia zostały oznaczone jako przeczytane",
            type: "success",
            duration: 2500,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$notify({
            title: "Błąd!",
            dangerouslyUseHTMLString: false,
            message:
              "Oznaczenie wszystkich powiadomieniń jako przeczytane nie powiodło się",
            type: "error",
            duration: 2500,
            position: "top-right",
          });
          console.log(error);
        });
    },
    getAll(page, maxItems, onlyUnread, module) {
      this.isProcessing = true;
      NotificationRepository.getNotifications(
        page,
        maxItems,
        onlyUnread,
        module
      )
        .then((response) => {
          this.countNotificationAll = response.data.info.countAllItems;
          this.countNotificationUnread = response.data.info.countUnreadItems;
          this.userNotifications = response.data.data.map((res) => ({
            ...res,
            isLoading: false,
          }));
          this.preloader = false;
          this.isProcessing = false;
        })
        .catch((error) => {
          this.preloader = false;
          this.isProcessing = false;
          console.log(error);
        });
    },
    clickToNotification(notification) {
      if (notification.link !== null) {
        window.location.href = notification.link;
      }
    },
    markAs(notification) {
      if (notification.markedAsRead === true) {
        this.markAsRead(notification);
      }

      if (notification.markedAsRead === false) {
        this.markAsUnread(notification);
      }
    },
    markAsRead(notification) {
      notification.isLoading = true;
      NotificationRepository.markAsRead(notification.notificationId)
        .then(() => {
          notification.isLoading = false;
          this.notifySuccess(notification);
          this.currentPage = 1;
          this.getAll(1, 10, this.paramType, this.$route.params.module);
          EventBus.$emit("notificationChanged");
        })
        .catch((error) => {
          notification.isLoading = false;
          console.log(error);
        });
    },
    markAsUnread(notification) {
      notification.isLoading = true;
      NotificationRepository.markAsUnread(notification.notificationId)
        .then(() => {
          notification.isLoading = false;
          this.notifySuccess(notification);
          this.currentPage = 1;
          this.getAll(1, 10, this.paramType, this.$route.params.module);
          EventBus.$emit("notificationChanged");
        })
        .catch((error) => {
          notification.isLoading = false;
          console.log(error);
        });
    },
    isFromToday(notification) {
      return (
        this.todayDate ===
        new Date(notification.datetime).toISOString().slice(0, 10)
      );
    },
    ntColor(type) {
      return type === "error" ? "danger" : "success";
    },
    notifySuccess(notification) {
      this.$notify({
        title: "Sukces!",
        dangerouslyUseHTMLString: true,
        message:
          "Status powiadomienia<br/>" +
          notification.notificationId +
          "<br/>został zmieniony",
        type: "success",
        duration: 2500,
        position: "top-right",
      });
    },
  },
  computed: {
    rows() {
      if (this.$route.params.type === "unread") {
        return this.countNotificationUnread;
      } else {
        return this.countNotificationAll;
      }
    },
    paramType() {
      return this.$route.params.type === "unread" ? true : false;
    },
    displayReadStatus() {
      if (this.$route.params.type === "all") {
        return "Wszystkie powiadomienia";
      } else if (this.$route.params.type === "unread") {
        return "Nieprzeczytane powiadomienia";
      }
      return "Powiadomienia";
    },
    displayCategory() {
      if (this.$route.params.module === "all") {
        return "ze wszystkich kategorii";
      } else if (this.$route.params.module === "Payment") {
        return "z kategorii wnioski";
      }
      return "bez kategorii";
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="email-leftbar card shadow-sm">
          <b-link class="btn btn-primary btn-block" :to="{ name: 'Moje powiadomienia', params: { type: 'all', module: 'all' } }">Moje powiadomienia</b-link>
          <div class="mail-list mt-2">
            <b-link :active="$route.params.type === 'all'" :to="{ name: 'Moje powiadomienia', params: { type: 'all', module: $route.params.module } }">
              <span class="mdi mdi-arrow-right-drop-circle text-success float-right"></span>Wszystkie
              <span class="ml-1">({{ countNotificationAll }})</span>
            </b-link>
            <b-link :active="$route.params.type === 'unread'" :to="{ name: 'Moje powiadomienia', params: { type: 'unread', module: $route.params.module } }">
              <span class="mdi mdi-arrow-right-drop-circle text-info float-right"></span>Nieprzeczytane
              <span class="ml-1">({{ countNotificationUnread }})</span>
            </b-link>
          </div>
          <span class="btn btn-primary btn-block disabled mt-2">Kategorie</span>
          <div class="mail-list mt-2">
            <b-link :active="$route.params.module === 'all'" :to="{ name: 'Moje powiadomienia', params: { type: $route.params.type, module: 'all' } }">
              <span class="mdi mdi-arrow-right-drop-circle text-success float-right"></span>Wszystkie
            </b-link>
            <b-link :active="$route.params.module === 'Payment'" :to="{ name: 'Moje powiadomienia', params: { type: $route.params.type, module: 'Payment' } }">
              <span class="mdi mdi-arrow-right-drop-circle text-warning float-right"></span>Wnioski
            </b-link>
          </div>
        </div>
        <!-- End Left sidebar -->
        <div class="email-rightbar mb-3">
          <div class="card shadow-sm">
            <div class="border-bottom m-0 p-3 pb-0">
              <button v-on:click="markAsReadAll" class="btn btn-outline-warning"><i class="mdi mdi-checkbox-marked-outline mr-2"></i>Oznacz wszystkie jako przeczytane</button>
              <div class="btn-group float-right">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" class="p-0 m-0"></b-pagination>
              </div>
            </div>
            <div v-loading="isProcessing">
              <div class="card-header">{{ displayReadStatus }} {{displayCategory}}.</div>
              <template v-if="userNotifications.length === 0">
                <div class="d-flex justify-content-center mt-3 mb-3">
                  <b>Nie znaleziono powiadomień</b>
                </div>
              </template>
              <div class="text-reset notification-item message-list border-bottom" v-for="(notification,index) in userNotifications" :key="index">
                <div class="media d-flex align-items-center notifyitem" v-loading="notification.isLoading" :class="isFromToday(notification) ? 'bg-new-message border-'+ ntColor(notification.type) : 'border-'+ ntColor(notification.type)">
                  <b-form-checkbox class="mr-2" v-b-tooltip.hover :title="notification.markedAsRead ? 'Oznacz jako nieprzeczytane' : 'Oznacz jako przeczytane'" v-model="notification.markedAsRead" @change="markAs(notification)"></b-form-checkbox>
                  <div class="media-body">
                    <small class="text-muted">
                      <i class="mdi mdi-calendar-clock mr-1"></i>
                      {{ notification.datetime }}
                    </small>
                    <h6 class="m-0 p-0">
                      <i class="mdi mdi-alert-decagram text-danger mr-1" v-if="notification.priority === true"></i>
                      {{ notification.title }}<el-tag size="mini" v-if="isFromToday(notification)">new</el-tag>
                    </h6>
                    <div class="font-size-12 text-muted">
                      <p class="p-0 m-0" v-html="notification.content"></p>
                    </div>
                  </div>
                  <div class="text-right ml-3" v-if="notification.link !== null">
                    <el-button size="mini" v-on:click="clickToNotification(notification)">Zobacz</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-7"></div>
            <div class="col-5">
              <div class="btn-group float-right">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style lang="scss" scoped>
.notifyitem {
  border-left: 5px solid;
}

.el-tag--mini {
  height: 14px;
  padding: 0 5px;
  line-height: 8px;
  margin-left: 5px;
  vertical-align: text-top;
}
</style>
